@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Biko;
  }
  .break-word {
    word-break: break-word;
  }
}

@font-face {
  font-family: Biko;
  font-weight: 200;
  src: url("./assets/font/biko/Biko_Light-Restricted.otf") format('truetype');
}
@font-face {
  font-family: Biko;
  font-weight: 400;
  src: url("./assets/font/biko/Biko_Regular.otf") format('truetype');
}
@font-face {
  font-family: Biko;
  font-weight: 600;
  src: url("./assets/font/biko/Biko_Bold.otf") format('truetype');
}
@font-face {
  font-family: Biko;
  font-weight: 800;
  src: url("./assets/font/biko/Biko_Black.otf") format('truetype');
}

.inter-400 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

/* @font-face {
  font-family: Inter;
  font-weight: 200;
  src: url("./fonts/Inter-ExtraLight.ttf") format('truetype');
}
@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("./fonts/Inter-Light.ttf") format('truetype');
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("./fonts/Inter-Regular.ttf") format('truetype');
}
@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("./fonts/Inter-Medium.ttf") format('truetype');
}
@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("./fonts/Inter-SemiBold.ttf") format('truetype');
}
@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("./fonts/Inter-Bold.ttf") format('truetype');
} */
